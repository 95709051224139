/**
 * Created by Carlo on 08/06/2016.
 */
STE.Step1 = new function() {

  var _self = this;
  //var _map = null;

  var _endpoints = {
    'routes_select': '',
    'routes': '',
    'vehicles_of_category': '',
    'vehicle_brands': ''
  };

  var _uiSelectors = {
    'macroarea': '',
    'direction': '',
    'triptype': '',
    'triptype_oneway': '',
    'triptype_roundtrip': '',
    'outward_route': '',
    'outward_datepicker': '',
    'return_route': '',
    'return_datepicker': '',
    'vehicle_flag': '',
    'vehicle_category': '',
    'vehicle_code': '',
    'vehicle_brand': '',
    'vehicle_count': '',
    'trailer': '',
    'route': 'select.route',
    'pets': '',
    'children': '',
    'adults': ''
  };

  /**
   * Element caching
   * @private
   */
  var _elements = {};

  var _vehicleCategories = {
    'BRANDED_CARS': 'OTHER',
    'MEASURED_CARS': 'CAR',
    'CAMPERS': 'CAM',
    'MOTORCYCLES': 'MOT',
    'MINIBUS': 'MBV',
    'VAN': 'FRG',
    'BIKE': 'BIKE'
  };

  var _macroarea = '';
  var _routes = null;

  /**
   * Init function: adds event handlers, inits map, datepickers, etc.
   */
  this.onInit = function(endpoints, uiSelectors) {
    $.extend(_endpoints, endpoints);
    $.extend(_uiSelectors, uiSelectors);

    _elements = {
      'form': $('#search-form'),
      'macroarea': $(_uiSelectors.macroarea),
      'direction': $(_uiSelectors.direction),
      'triptype': $(_uiSelectors.triptype),
      'triptype_roundtrip': $(_uiSelectors.triptype_roundtrip),
      'outward_route': $(_uiSelectors.outward_route),
      'outward_datepicker': $(_uiSelectors.outward_datepicker),
      'return_route': $(_uiSelectors.return_route),
      'return_datepicker': $(_uiSelectors.return_datepicker),
      'return_route_panel': $('#return-panel'),
      'vehicle_flag': $(_uiSelectors.vehicle_flag),
      'vehicle_panel': $('#vehicle-panel'),
      'vehicle_category': $(_uiSelectors.vehicle_category),
      'vehicle_code': $(_uiSelectors.vehicle_code),
      'vehicle_code_panel': $('#vehicle-code-panel'),
      'vehicle_size_panel': $('#vehicle-size-panel'),
      'vehicle_brand': $(_uiSelectors.vehicle_brand),
      'vehicle_brand_panel': $('#vehicle-brand-panel'),
      'vehicle_count': $(_uiSelectors.vehicle_count),
      'vehicle_count_panel': $('#vehicle-count-panel'),
      'trailer_panel': $('#trailer-panel'),
      'trailer': $(_uiSelectors.trailer),
      'trailer_size_panel': $('#trailer-size-panel'),
      'vehicle_accessory_panel': $('#vehicle-accessory-panel'),
      'pets': $(_uiSelectors.pets),
      'pets_panel': $('#pets-panel'),
      'pets_types': $('.pet-type-container'),
      'adults': $(_uiSelectors.adults),
      'children': $(_uiSelectors.children),
      'children_panel': $('#children-panel'),
      'children_ages': $('.children-age-container')
    };

    _macroarea = _elements.macroarea.val();

    $.get(_endpoints.routes, {
      'area': _macroarea
    }, function(data) {
      _routes = data;
      //_map = STE.Maps.initialize('map');

      _elements.direction.on('change', _self.onDirectionChanged).trigger('change');
      _elements.triptype.on('change', _self.onTripTypeChanged);
    });

    if ($(_uiSelectors.triptype_oneway).is(':checked')) {
      _elements.return_route_panel.hide();
    }

    _elements.pets.on('change', _self.onPetsNumberChanged).triggerHandler('change');
    _elements.children.on('change', _self.onChildrenNumberChanged).triggerHandler('change');
    _elements.children_panel.find('select').on('change', _childrenAlert).triggerHandler('change');
    _elements.adults.on('change', _self.onAdultsNumberChanged).triggerHandler('change');

    STE.UI.dateRangePicker('.datepicker', {
      todayHighlight: true,
      startDate: '0'
    }).on('changeDate', function(e) {
      if ($(e.currentTarget).is(_elements.outward_datepicker)) {
        _childrenAlert();
      }
    });
    _elements.vehicle_category.on('change', _self.onVehicleCategoryChanged).trigger('change');
    _elements.trailer.on('change', _self.onTrailerChanged).trigger('change');
    _elements.vehicle_flag.on('change', _self.onVehicleFlagChanged);
    _elements.vehicle_panel.css('display', _elements.vehicle_flag.is(':checked') ? 'block' : 'none');
  };

  var _childrenAlert = function() {
    var children = parseInt(_elements.children.val());
    var $alt = $('[name="' + _elements.outward_datepicker.data('datealt') + '"]');
    var date = moment($alt.val());

    if (children > 0) {
      for (var i = 1; i <= children; i++) {
        var $age = $('#children-age-' + i);
        var $alert = $('#children-age-alert-' + i);
        if ($age.val() === '') {
          $alert.hide();
        } else {
          $alert.show();
        }
        var age = parseInt($age.val());

        var startDate = date.clone().subtract(age + 1, 'years').add(1, 'days');
        var endDate = date.clone().subtract(age, 'years');

        console.log(endDate.format('DD/MM/YYYY'));

        $alert.html(Lang.get('labels.step1_children_alert', {start: startDate.format('DD/MM/YYYY'), end: endDate.format('DD/MM/YYYY')}));
      }
    }
  };

  this.askRestart = function(macroarea, portal, affiliate, language) {
    STE.UI.Popups.confirm(Lang.get('labels.step1_restart_dialog_title'), Lang.get('labels.step1_restart_dialog_message'), null, function() {
      window.location.href = '/?marea=' + macroarea + '&portal=' + portal + '&affiliate=' + affiliate + '&locale=' + language;
    }, Lang.get('labels.step1_restart_dialog_stay'), Lang.get('labels.step1_restart_dialog_restart'));
  };

  /**
   * Triggered when the "one way"/"return journey" radio buttons are clicked.
   * If the journey has the return route, we show the panel containing the return route and date controls,
   * and we add the routes to the map. Else, we hide the controls and the routes.
   * @param e
   */
  this.onTripTypeChanged = function(e) {
    var $this = $(e.target);
    //_showHideRoutesOnMap();

    if ($this.val() === 'R') {
      _elements.return_route_panel.slideDown('fast');
    } else {
      _elements.return_route_panel.slideUp('fast');
      _elements.return_datepicker.datepicker('clearDates');
    }
  };

  this.onPetsNumberChanged = function(e) {
    var $this = $(e.target);
    var pets = parseInt($this.val());
    _elements.pets_panel.toggleClass('hidden', pets <= 0);
    if (pets > 0) {
      var petTypes = _elements.pets_types.length;
      for (var i = 1; i <= petTypes; i++) {
        if (i <= pets) {
          $('#pet-type-container-' + i).css('opacity', '1.0').find('select').removeClass('hidden');
        } else {
          $('#pet-type-container-' + i).css('opacity', '0').find('select').addClass('hidden');
        }
      }
    }
  };

  this.onChildrenNumberChanged = function(e) {
    var $this = $(e.target);
    var children = parseInt($this.val());
    _elements.children_panel.toggleClass('hidden', children <= 0);
    if (children > 0) {
      var ages = _elements.children_ages.length;
      for (var i = 1; i <= ages; i++) {
        if (i <= children) {
          $('#children-age-container-' + i).css({
            'height': 'auto',
            'overflow': 'auto'
          }).find('select').removeClass('hidden');
        } else {
          $('#children-age-container-' + i).css({
            'height': '0',
            'overflow': 'hidden'
          }).find('select').addClass('hidden');
        }
      }

      _childrenAlert();
    }
  };

  this.onAdultsNumberChanged = function(e) {
    var $this = $(e.target);
    var adults = parseInt($this.val());

    if (_elements.vehicle_count_panel.length > 0) {
      _elements.vehicle_count.find('option').each(function() {
        var value = parseInt($(this).attr('value'));
        $(this).prop('disabled', adults < value);

        if (adults < value && $(this).is(':selected')) {
          $(this).prop('selected', false);
        }
      });
    }
  };

  this.onTrailerChanged = function(e) {
    var $this = $(e.target);
    if ($this.val() === '') {
      _elements.trailer_size_panel.hide();
    } else {
      _elements.trailer_size_panel.show();
    }
  };

  this.onDirectionChanged = function(e) {
    var $this = $(e.target);
    var direction = $this.val();

    $.get(_endpoints.routes_select, {
      'area': _macroarea
    }, function(data) {
      _elements.outward_route.html(direction === 'from' ? data['from'] : data['to']).on('change', _self.onRouteChanged);
      _elements.return_route.html(direction === 'from' ? data['to'] : data['from']).on('change', _self.onRouteChanged);

      var outwardSelected = _elements.outward_route.data('value') || '';
      if (outwardSelected && _elements.outward_route.find('option[value="' + outwardSelected + '"]').length)
        _elements.outward_route.val(outwardSelected);

      var returnSelected = _elements.return_route.data('value') || '';
      if (returnSelected && _elements.return_route.find('option[value="' + returnSelected + '"]').length)
        _elements.return_route.val(returnSelected);

      //_showHideRoutesOnMap();
    });
  };

  /**
   * Triggered when a route is changed FROM the select control.
   * @param e the change event of the select control
   */
  this.onRouteChanged = function(e) {
    var $this = $(e.target);
    var $option = $this.find(':selected');

    var direction = $option.data('direction');
    var from = $option.data('from');
    var to = $option.data('to');

    //_showHideRoutesOnMap();

    if ($this.is(_elements.outward_route)) {
      var $inverse = $('option[data-from="' + to + '"][data-to="' + from + '"][data-direction="' + (direction === 'from' ? 'to' : 'from') + '"]');
      if (!$inverse.parent().attr('data-manuallyset')) {
        $inverse.prop('selected', 'selected').parent().trigger('change');
      }
    }
  };

  this.manageNoResults = function() {
    var $selectedOutwardRoute = _elements.outward_route.find(':selected');
    if ($selectedOutwardRoute.hasClass('route-name')) {
      $('#extend-search-message').removeClass('hidden');
      $('#extend-search-panel').removeClass('hidden');
      $('#extend-search-button').on('click', function(e) {
        e.preventDefault();
        $selectedOutwardRoute.prop('selected', false);
        $selectedOutwardRoute.prev('.route-direction').prop('selected', true);
        _elements.outward_route.trigger('change');
        setTimeout(function() {
          _elements.form.submit();
        }, 500);
      });
    } else {
      $('#extend-search-message').addClass('hidden');
      $('#extend-search-panel').addClass('hidden');
      $('#extend-search-button').off('click');
    }

    $('#no-results-panel').removeClass('hidden');
  };

  /*var _showHideRoutesOnMap = function () {
      var tripType = _elements.triptype.filter(':checked').val();
      var outwardRoute = _elements.outward_route.val();
      var returnRoute = _elements.return_route.val();

      if (outwardRoute.indexOf(';') > 0) {
          outwardRoute = outwardRoute.split(';');
      } else outwardRoute = [outwardRoute];

      if (returnRoute.indexOf(';') > 0) {
          returnRoute = returnRoute.split(';');
      } else returnRoute = [returnRoute];

      _map.clearMap();

      var i = 0;
      for (; i < outwardRoute.length; i++) {
          _addRouteOnMap(_routes[outwardRoute[i]], 'A');
      }

      if (tripType === 'R') {
          for (i = 0; i < returnRoute.length; i++) {
              _addRouteOnMap(_routes[returnRoute[i]], 'R');
          }
      }

      _map.fitBounds();
  };

  var _addRouteOnMap = function (route, type) {
      try {
          _map.addRouteFromEncodedPath(route['code'], route['coords_encoded'], route['name'], type);
          _map.addPortFromLatLng(route['port_from']['code'], route['port_from']['lat'], route['port_from']['lng'], route['port_from']['name']);
          _map.addPortFromLatLng(route['port_to']['code'], route['port_to']['lat'], route['port_to']['lng'], route['port_to']['name']);
      } catch (e) {

      }
  };*/

  this.onVehicleFlagChanged = function(e) {
    var $this = $(e.target);

    if ($this.is(':checked')) {
      _elements.vehicle_panel.slideDown('fast');
      _elements.vehicle_category.trigger('change');
    } else {
      _elements.vehicle_panel.slideUp('fast');
    }
  };

  var _vehicleCache = {
    'of_category': [],
    'branded': ''
  };
  this.onVehicleCategoryChanged = function(e) {
    var $this = $(e.target);
    var cat = $this.val();

    switch (cat) {
      case '':
        _elements.vehicle_size_panel.hide();
        _elements.vehicle_brand_panel.hide();
        _elements.vehicle_code_panel.hide();
        _elements.trailer_panel.hide();
        _elements.vehicle_accessory_panel.hide();
        _elements.vehicle_count_panel.hide();
        break;
      case _vehicleCategories.MOTORCYCLES:
        _elements.vehicle_size_panel.hide();
        _elements.vehicle_brand_panel.hide();
        _elements.vehicle_code_panel.show();
        _elements.vehicle_count_panel.hide();

        if (cat === _vehicleCategories.MOTORCYCLES) {
          _elements.trailer_panel.hide();
          _elements.vehicle_accessory_panel.hide();
        } else {
          _elements.trailer_panel.show();
          _elements.vehicle_accessory_panel.show();
        }

        if (!_vehicleCache.of_category[cat]) {
          var lang = STE.getLocale();
          $.get(_endpoints.vehicles_of_category, {
            'category': cat
          }, function(data) {
            var options = ['<option value="">' + Lang.get('labels.forms_emptyoption_select') + '</option>'];
            $.each(data, function(i) {
              options.push('<option value="' + data[i].code + '">' + data[i]['translations'][lang] + '</option>');
            });

            _vehicleCache.of_category[cat] = options.join('');
            _elements.vehicle_code.html(_vehicleCache.of_category[cat]);

            STE.UI.setSelectValue(_elements.vehicle_code, _elements.vehicle_code.data('value'));
          });
        } else {
          _elements.vehicle_code.html(_vehicleCache.of_category[cat]);
          STE.UI.setSelectValue(_elements.vehicle_code, _elements.vehicle_code.data('value'));
        }
        break;
      case _vehicleCategories.MEASURED_CARS:
      case _vehicleCategories.CAMPERS:
      case _vehicleCategories.MINIBUS:
      case _vehicleCategories.VAN:
        _elements.vehicle_size_panel.show();
        _elements.trailer_panel.show();
        _elements.vehicle_code_panel.hide();
        _elements.vehicle_brand_panel.hide();
        _elements.vehicle_accessory_panel.hide();
        _elements.vehicle_count_panel.hide();
        break;
      case _vehicleCategories.BIKE:
        _elements.vehicle_size_panel.hide();
        _elements.trailer_panel.hide();
        _elements.vehicle_code_panel.hide();
        _elements.vehicle_brand_panel.hide();
        _elements.vehicle_accessory_panel.hide();
        _elements.vehicle_count_panel.show();
        break;
      case _vehicleCategories.BRANDED_CARS:
        _elements.vehicle_brand_panel.show();
        _elements.vehicle_size_panel.hide();
        _elements.vehicle_code_panel.hide();
        _elements.trailer_panel.show();
        _elements.vehicle_accessory_panel.show();
        _elements.vehicle_count_panel.hide();

        if (_vehicleCache.branded === '') {
          $.when(
              $.get(_endpoints.vehicle_brands, function(data) {
                var options = ['<option value="">' + Lang.get('labels.forms_emptyoption_select') + '</option>'];
                $.each(data, function(i) {
                  options.push('<option value="' + data[i] + '">' + data[i] + '</option>');
                });

                _vehicleCache.branded = options.join('');
                _elements.vehicle_brand.html(_vehicleCache.branded);
                STE.UI.setSelectValue(_elements.vehicle_brand, _elements.vehicle_brand.data('value'));
              })
          ).then(function() {
            _elements.vehicle_brand.trigger('change');
          });
        } else {
          _elements.vehicle_brand.html(_vehicleCache.branded);
          STE.UI.setSelectValue(_elements.vehicle_brand, _elements.vehicle_brand.data('value'));
          _elements.vehicle_brand.trigger('change');
        }
        break;
    }
  };
};
